<template>
  <div class='w-full'>

    <div class="d-flex mb-4 w-full ">
      <button class='btn-primary rounded-sm py-2 px-4' @click='isMapView = !isMapView'>{{ isMapView ? 'List View' :
        'MapView' }}</button>
      <div class="d-flex align-items-center ml-4 " v-if='isMapView'>
        <input type="radio" id="home" value="home" v-model="markerType" class='ml-2' />
        <label for="home" class='mb-0 ml-2'>Home Address</label>

        <input type="radio" id="bus_stop" value="bus_stop" v-model="markerType" class='ml-5' />
        <label for="bus_stop" class='mb-0 ml-2'>Closest Bus Stop</label>

        <input type="radio" id="both" value="both" v-model="markerType" class='ml-5' />
        <label for="both" class='mb-0 ml-2'>Both</label>

        <input type="radio" id="both_with_line" value="both_with_line" v-model="markerType" class='ml-5' />
        <label for="both_with_line" class='mb-0 ml-2'>Both With Lines</label>
      </div>
    </div>
    <div class="card" v-show='!isMapView'>
      <div class="card-header justify-content-between">
        <div class="input-group input-group-flush d-flex flex-row-reverse">
          <input v-model.trim="search" class="form-control list-search" type="search" placeholder="Search" @keyup.enter.prevent="fetchStaff()" />
          <span class="input-group-text border-0">
            <i class="fe fe-search pointer" @click.prevent="fetchStaff()"></i>
          </span>
        </div>
        <div class="col-auto" v-if="staffList.length">
          <v-datepicker style="width: 100%" v-model="dateRange" placeholder="Filter by date" range></v-datepicker>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="p-3">
          <label for="status_active" class="d-block-inline px-2">
            <input type="radio" v-model="statusFilter" name="status" value="active" id="status_active" />
            Active
          </label>
          <label for="status_inactive" class="d-block-inline px-2">
            <input type="radio" v-model="statusFilter" name="status" value="inactive" id="status_inactive" />
            Inactive
          </label>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="mr-4">
            <download-button class="cursor-pointer" v-if="staffList.length" :downloading="downloadingReport" label="Download Report" @download="downloadReport()"></download-button>
          </div>
        </div>
      </div>

      <div class="card-body p-0">
        <b-table ref="staffTable" :fields="fields" :current-page="currentPage" :busy="staffLoading" responsive striped :items="staffList">
          <template #table-busy>
            <div class="text-center text-secondary my-2">
              <strong>Loading...</strong>
            </div>
          </template>
          <template #empty>
            <h4>There are no staff found</h4>
          </template>

          <template #cell(id)="data">
            <span>{{ staffList.findIndex((item) => item.id === data?.item?.id) + 1 }}</span>
          </template>
          <template #cell(name)="data">
            <router-link v-if="data?.item?.fname || data?.item?.lname" :to="{
              name: 'ShowUser',
              params: {
                userId: data.item.id,
              },
            }">
              <span>{{ data.item.fname }} {{ data.item.lname }}</span> <br>
              <span>{{ data.item.email }}</span>
            </router-link>
            <span v-else class="text-muted">N/A</span>
          </template>


          <template #cell(home_address)="data">
            <span>{{ data?.item?.address?.address ?? 'N/A' }}</span>
          </template>

          <template #cell(nearest_busstop)="data">
            <span>{{
              data?.item?.address?.closestBusstop?.address ?? 'N/A'
            }}</span>
          </template>

          <template #cell(office_branch)="data">
            <span>{{
              data?.item?.workShift?.officeBranch?.address ?? 'N/A'
            }}</span>
          </template>

          <template #cell(work_shift)="data">
            <span>{{
              data?.item?.workShift?.corporate_work_shift?.description ??
              'N/A'
            }}</span>
          </template>

          <template #cell(wallet)="data">
            <span v-if='data?.item?.wallet?.credit_amount'>{{ convertToNaira(data?.item?.wallet?.credit_amount) }}</span>
            <span v-else>N/A</span>
          </template>

          <template #cell(preferred_routes)="data">
            <div class="w-40 flex gap-2">
              <span>{{ data?.item?.preferredRoutes?.length ?? 'N/A' }}</span>
              <span class="fe fe-eye" @click='openViewStaffToRouteModal(data?.item)' v-if='data?.item?.preferredRoutes?.length'></span>
              <span class="fe fe-plus-circle text-green-600 cursor-pointer" @click='openAssignStaffToRouteModal(data?.item)'></span>
            </div>

          </template>

          <template #cell(work_days)="data">
            <div class="days_parent" v-if="data?.item?.workShift?.work_days">
              <span v-for="(workDay, index) in data?.item?.workShift?.work_days" :key="index">{{ workDay ?? 'N/A'
              }},</span>
            </div>
            <div v-else> N/A </div>
          </template>
        </b-table>
        <div class="card-footer" v-if="totalRecords">
          <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
        </div>
      </div>
    </div>

    <div v-show='isMapView' class='card ' style='height: 600px;' id='google-map' />

    <BS_modal :show='assignStaffToRouteModal' title='Assign Staff to Route' @close='assignStaffToRouteModal = false' modalId='assignStaffToRouteModal'>
      <div class="form-group mb-3">

        <div class="form-group mb-3">
          <label class="form-label">Select Route</label>
          <v-select v-model="form.route.value" class="form-group" placeholder="Search" :reduce="route => route.id" :options="routes" label="route_code" required>
            <template v-slot:option="route">
              <span>{{ `${route.route_code ? route.route_code + ' - ' : ''} From ${route.pickup} to ${route.destination}` }}</span>
            </template>
            <template v-slot:selected-option="route">
              <span>{{ `${route.route_code ? route.route_code + ' - ' : ''} From ${route.pickup} to ${route.destination}` }}</span>
            </template>
          </v-select>
        </div>

        <div class="form-group mb-3" v-if="!fetchingItineraries && itineraries.length">
          <label class="form-label">Assign Itinerary</label>
          <select class="form-control" v-model="form.itinerary.value">
            <option :value="null">-Select-</option>
            <option v-for="(option, index) in itineraries" :value="option.id" :key="index">{{ option.trip_time }}</option>
          </select>
        </div>

        <div v-else class="form-group">
          <div class="d-flex align-items-center" v-if="fetchingItineraries">
            <div class="spinner-border spinner" role="status"></div>
            <p class="text-center m-0 loading-text"><span>Loading route itineraries...</span></p>
          </div>
          <p v-else-if="form.route">
            No itineraries available for selected route.
          </p>
          <p v-else>Please select a route</p>
        </div>
      </div>
      <footer>
        <b-button :disabled="assignStaffToRouteModalLoading || !isValidForm" variant="primary" class="w-full mt-4" @click="assignStaffToRoute">
          {{ assignStaffToRouteModalLoading ? 'Processing...' : 'Assign' }}
        </b-button>
      </footer>
    </BS_modal>

    <BS_modal size='lg' :show='viewStaffRouteModal' title="View Staff's Route" @close='viewStaffRouteModal = false' modalId='viewStaffRouteModal'>
      <b-table v-if='selectedUser' striped hover selectable show-empty responsive :items="selectedUser.preferredRoutes" :fields="preferredRoutesFields">
        <template #cell(route_code)="data">
          {{ data.item.route.route_code }}
        </template>
        <template #cell(route)="data">
          <route-description :max-width="250" :pickup="data.item.route.pickup" :destination="data.item.route.destination">
          </route-description>
        </template>
        <template #cell(itinerary)="data">
          {{ data.item.itinerary.trip_time }}
        </template>
        <template #cell(id)="data">
          <button class='btn-primary' @click='deleteAssignedRoute(data.item)' :disabled='data.item.loading'>{{ data.item.loading ? 'Loading...' : 'Delete' }}</button>
        </template>
      </b-table>
    </BS_modal>

  </div>
</template>

<script setup>
import Swal from 'sweetalert2'
import { Loader } from '@googlemaps/js-api-loader'
import office_svg from '@/assets/img/icons/office_marker.svg'
import marker_svg from '@/assets/img/icons/home_marker.svg'
import busstop_marker_svg from '@/assets/img/icons/stop_marker.svg'
import { monitizeValue } from '@/utils/helpers'
import { useRouter } from 'vue-router/composables'
import { ref, onMounted, computed, defineProps, watch, inject, set, getCurrentInstance } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import VueToastr from 'vue-toastr'
import BS_modal from '@/components/core/modals/BS_modal.vue'
import RouteDescription from '@/components/modules/routes/RouteDescription'
import routeResource from '@/api/route'
import DownloadButton from '@/components/core/DownloadButton.vue'
import { joinWithRatingsData, extractErrorMessage } from '@/utils/helpers'
import { ExportToCsv } from 'export-to-csv'
import { format } from 'date-fns'


const statusFilter = ref('active')
const downloadingReport = ref(false)
const dateRange = ref([])

const form = {
  route: ref(null),
  itinerary: ref(null),
  vehicle: ref(null)
}

const gMapLoader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLE_APIKEY,
  version: 'weekly',
  libraries: ['geometry', 'marker']
})
const staffTable = ref(null)
const router = useRouter()


const openAssignStaffToRouteModal = (data) =>
{
  selectedUser.value = data
  assignStaffToRouteModal.value = true
}
const openViewStaffToRouteModal = (data) =>
{
  selectedUser.value = data
  selectedUser.value.preferredRoutes = data.preferredRoutes.map((route) =>
  {
    return {
      ...route,
      loading: false
    }
  })
  viewStaffRouteModal.value = true
}

const props = defineProps({
  companyId: {
    required: true,
    type: [Number, String]
  }
})

const isValidForm = computed(() =>
{
  return form.route.value && form.itinerary.value
})
const assignStaffToRouteModal = ref(false)
const viewStaffRouteModal = ref(false)
const assignStaffToRouteModalLoading = ref(false)
const staffLoading = ref(false)
const isMapView = ref(false)
const currentPage = ref(1)
const totalRecords = ref(0)
const perPage = ref(20)
const selectedUser = ref(null)
const fetchingWorkBranches = ref(false)
const corporateWorkBranches = ref([])
const fetchingItineraries = ref(false)
const markerType = ref('home')
const routes = ref([])
const itineraries = ref([])
const staffList = ref([])
const home_markers = ref([])
const bus_stop_markers = ref([])
const polyline_markers = ref([])
const polyline_trace_markers = ref([])
const mapRef = ref(null)
const search = ref('')
const preferredRoutesFields = ref([
  { key: 'route_code', label: 'Route Code' }, { key: 'route', label: 'Route' }, { key: 'itinerary', label: 'Itinerary' }, { key: 'id', label: 'Action' }
])
const fields = ref([
  {
    key: 'id',
    label: 'ID'
  },
  {
    key: 'name',
    label: 'NAME'
  },
  {
    key: 'home_address',
    label: 'HOME ADDRESS'
  },
  {
    key: 'nearest_busstop',
    label: 'NEAREST BUS STOP'
  },
  {
    key: 'office_branch',
    label: 'OFFICE BRANCH'
  },
  {
    key: 'work_shift',
    label: 'WORK SHIFT'
  },
  {
    key: 'work_days',
    label: 'WORK DAYS'
  },
  {
    key: 'wallet',
    label: 'CREDIT BALANCE'
  },
  {
    key: 'preferred_routes',
    label: 'PREFERRED ROUTES'
  },
])


const polylineOptions = ref({
  geodesic: true,
  strokeColor: '#FF0000',
  strokeOpacity: 1.0,
  strokeWeight: 2
})

watch(isMapView, (val) =>
{
  if (val) {
    perPage.value = totalRecords.value
    fetchStaff()
  }
})

watch(markerType, () =>
{
  setMapOnAll(mapRef.value)
})

watch(form.route, (value, oldValue) =>
{
  if (value && value !== oldValue) {
    form.itinerary.value = null
    fetchRouteItineraries()
  }
})

onMounted(() =>
{
  loadMap()
})

const fetchRoutes = () =>
{
  routeResource.listRoute({
    params: { status: 1, limit: 1000000, corporate_id: props.companyId }
  }).then((res) =>
  {
    routes.value = res
  })
}

fetchRoutes()

const loadCorporateWorkBranches = async () =>
{
  fetchingWorkBranches.value = true
  await axiosInstance
    .get(`/v1/corporates/${props.companyId}/office-branches`)
    .then((res) =>
    {
      corporateWorkBranches.value = res?.data?.data || []
    })
    .catch((error) =>
    {
      VueToastr.e(error?.response?.data?.message, 'Error')
    })
    .finally(() =>
    {
      fetchingWorkBranches.value = false
    })
}

const convertToNaira = (amount) =>
{
  return monitizeValue(amount)
}
const deleteAssignedRoute = (item) =>
{
  item.loading = true
  const data = item
  Swal.fire({
    icon: 'question',
    title: 'Please Confirm',
    text: 'Are you sure you want to remove this route?',
    showConfirmButton: true,
    showCancelButton: true,
    preDeny: () =>
    {
      item.lo2ading = false
    },
    preConfirm: () =>
    {
      return axiosInstance.delete(`/v1/preferred-routes/${item.id}`).then(() =>
      {
        selectedUser.value.preferredRoutes = selectedUser.value.preferredRoutes.filter((route) => route.id !== data.id)
        item.loading = false
        Swal.fire({
          title: 'Success',
          text: 'Staff route deleted successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      }).catch((err) =>
      {
        Swal.fire({
          title: 'Error',
          text: err?.response?.data?.message || 'An error occured',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        item.loading = false
      })
    },
    allowOutsideClick: () => !Swal.isLoading()
  })
}
const assignStaffToRoute = () =>
{
  assignStaffToRouteModalLoading.value = true
  axiosInstance.post('/v1/preferred-routes', {
    user_id: selectedUser.value.id,
    itinerary_ids: [form.itinerary.value],
  }).then((res) =>
  {
    assignStaffToRouteModal.value = false
    Swal.fire({
      title: 'Success',
      text: 'Staff assigned to route successfully',
      icon: 'success',
      confirmButtonText: 'Ok'
    })
  }).catch((err) =>
  {
    Swal.fire({
      title: 'Error',
      text: err?.response?.data?.message || 'An error occured',
      icon: 'error',
      confirmButtonText: 'Ok'
    })
  }).finally(() =>
  {
    assignStaffToRouteModalLoading.value = false
    form.route.value = null
    form.itinerary.value = null
    fetchStaff()
  })
}

const loadMap = () =>
{
  gMapLoader
    .load()
    .then(() =>
    {
      mapRef.value = new window.google.maps.Map(
        document.getElementById('google-map'),
        {
          center: { lat: 6.5244, lng: 3.3792 },
          zoom: 12,
          mapId: process.env.VUE_APP_MAP_ID,
          streetViewControl: false,
          fullscreenControl: false,
          disableDefaultUI: true,
          zoomControl: true
        }
      )
    })
}
const fetchRouteItineraries = () =>{
  itineraries.value = []
  fetchingItineraries.value = true
  axiosInstance.get(`/v1/routes/${form.route.value}/itineraries?itinerary_only=1`)
    .then((res) => { itineraries.value = res.data.data })
    .catch((err) => { })
    .finally(() => fetchingItineraries.value = false)
}

const setMapOnAll = (map) =>
{
  if (markerType.value === 'home') {
    for (const element of home_markers.value) {
      element.setMap(map)
    }
    for (const element of bus_stop_markers.value) {
      element.setMap(null)
    }
    for (const element of polyline_trace_markers.value) {
      element.setMap(null)
    }
  } else if (markerType.value === 'bus_stop') {
    for (const element of bus_stop_markers.value) {
      element.setMap(map)
    }
    for (const element of home_markers.value) {
      element.setMap(null)
    }
    for (const element of polyline_trace_markers.value) {
      element.setMap(null)
    }
  } else if (markerType.value === 'both') {
    for (const element of bus_stop_markers.value) {
      element.setMap(map)
    }
    for (const element of home_markers.value) {
      element.setMap(map)
    }
    for (const element of polyline_trace_markers.value) {
      element.setMap(null)
    }
  } else if (markerType.value === 'both_with_line') {
    for (const element of bus_stop_markers.value) {
      element.setMap(map)
    }
    for (const element of home_markers.value) {
      element.setMap(map)
    }
    for (const element of polyline_trace_markers.value) {
      element.setMap(map)
    }
  }
}

const loadExternalDataMarkers = async (dataArray) =>
{
  // this.mapRef
  for (const data of corporateWorkBranches.value) {
    const x = data?.location.y
    const y = data?.location.x
    const dataLocation = { lat: x, lng: y }
    const infowindow = new window.google.maps.InfoWindow({
      content: `
          <div class="text-sm font-medium"> ${data?.address} </div>
          `,
      ariaLabel: 'Shuttlers'
    })
    const marker = new window.google.maps.Marker({
      position: dataLocation,
      map: mapRef.value,
      icon: office_svg
    })
    marker.addListener('click', () =>
    {
      infowindow.open({
        anchor: marker,
        map: mapRef.value
      })
    })
  }

  for (const [inx, data] of dataArray.entries()) {
    polyline_markers.value[inx] = []
    if (data?.address?.location) {
      const x = data?.address?.location.y
      const y = data?.address?.location.x
      const dataLocation = { lat: x, lng: y }
      polyline_markers.value[inx].push(dataLocation)

      const infowindow = new window.google.maps.InfoWindow({
        content: `
            <div class="flex flex-col items-start"> <span class="text-sm font-medium">Address:</span> <span>${data?.address?.address || 'N/A'}</span> </div>
            <div class="flex flex-col items-start"> <span class="text-sm font-medium">Staff Name:</span> <span>${data?.fname} ${data?.lname}</span> </div>
            <div class="flex flex-col items-start"> <span class="text-sm font-medium">Work Shift:</span> <span>${data?.workShift?.corporate_work_shift?.description || 'N/A'}</span> </div>
            <div class="flex flex-col items-start"> <span class="text-sm font-medium">Office Branch:</span> <span>${data?.workShift?.officeBranch?.address || 'N/A'}</span> </div>
            `,
        ariaLabel: 'Shuttlers'
      })
      const marker = new window.google.maps.Marker({
        position: dataLocation,
        map: mapRef.value,
        icon: marker_svg
      })
      marker.addListener('click', () =>
      {
        infowindow.open({
          anchor: marker,
          map: mapRef.value
        })
      })
      home_markers.value.push(marker)
    }

    if (data?.address?.closestBusstop) {
      const x = data?.address?.closestBusstop?.location.y
      const y = data?.address?.closestBusstop?.location.x
      const dataLocation = { lat: x, lng: y }
      polyline_markers.value[inx].push(dataLocation)
      const infowindow = new window.google.maps.InfoWindow({
        content: `
            <div class="flex flex-col items-start"> <span class="text-sm font-medium">closest bus stop:</span> <span> ${data?.address?.closestBusstop?.address || 'N/A'}</span> </div>
            <div class="flex flex-col items-start"> <span class="text-sm font-medium">Staff Name:</span> <span>${data?.fname} ${data?.lname}</span> </div>
            <div class="flex flex-col items-start"> <span class="text-sm font-medium">Work Shift:</span> <span>${data?.workShift?.corporate_work_shift?.description || 'N/A'}</span> </div>
            <div class="flex flex-col items-start"> <span class="text-sm font-medium">Office Branch:</span> <span>${data?.workShift?.officeBranch?.address || 'N/A'}</span> </div>
            `,
        ariaLabel: 'Shuttlers'
      })
      const marker = new window.google.maps.Marker({
        position: dataLocation,
        map: mapRef.value,
        icon: busstop_marker_svg
      })
      marker.addListener('click', () =>
      {
        infowindow.open({
          anchor: marker,
          map: mapRef.value
        })
      })
      bus_stop_markers.value.push(marker)
    }
  }
  for (const polyline of polyline_markers.value) {
    if (polyline.length > 1) {
      const updatedPolylineOptions = {
        path: polyline,
        ...polylineOptions.value
      }
      const google_polyline_ref = new window.google.maps.Polyline(updatedPolylineOptions)
      polyline_trace_markers.value.push(google_polyline_ref)
    }
  }

  setMapOnAll(mapRef.value)
}

const dateFilterValid = computed(() =>{
  return (
    dateRange.value.length &&
    dateRange.value.filter((date) => !!date).length > 0
  )
})

const fetchStaff = async (showLoader) =>
{
    if (showLoader) {
    staffLoading.value = true
    currentPage.value = 1
  }
  staffLoading.value = true
  await loadCorporateWorkBranches()
  const result = await axiosInstance.get(
    `/v1/corporates/${props.companyId}/staff?search=${search.value}&page=${currentPage.value
    }&status=${statusFilter.value}&limit=${perPage.value}&startDate=${dateRange.value[0] ? format(dateRange.value[0], 'yyyy-MM-dd') : ''}
      &endDate=${dateRange.value[1] ? format(dateRange.value[1], 'yyyy-MM-dd') : ''}`,
  )

  loadExternalDataMarkers(result.data.data)
  staffList.value = result.data.data
  totalRecords.value = result.data?.metadata?.total
  staffLoading.value = false
}

fetchStaff()


const downloadReport = async () => {
  try {
    downloadingReport.value = true
    const result =  await axiosInstance.get(
    `/v1/corporates/${props.companyId}/staff?q=${search.value}&page=${currentPage.value
    }&status=${statusFilter.value}&limit=${perPage.value}&startDate=${dateRange.value[0] ? format(dateRange.value[0], 'yyyy-MM-dd') : ''}
      &endDate=${dateRange.value[1] ? format(dateRange.value[1], 'yyyy-MM-dd') : ''}`,
  )
    const csvData = result?.data?.data

    //   .map((data) =>
    //   {
    //   return {
    //     fullName: data.contact_fname + ' ' + data.contact_lname,
    //     corporate_emails: data.corporate_email,
    //     corporate_phone: data.corporate_phone,
    //     corporate_name: data.corporate_name,
    //     company_staff_strength: data.staff_strength,
    //     request_date: moment(data.created_at).format('MMMM Do YYYY')
    //   }
    // })

    const csvParams = {
      fieldSeparator: ',',
      filename: 'Companies Staff List',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
        filename: 'Companies Staff List',
      useTextFile: false,
      useBom: true,
      //   headers: [
      //   'Vehicle',
      //   'PlateNumber',
      //   'Capacity',
      //   'Type',
      //   'Amenities',
      //   'TotalTrips',
      //   'Driver',
      //   'Date_Joined'
      // ]
    }

    const csvExporter = new ExportToCsv(csvParams)

    csvExporter.generateCsv(csvData)
    Swal.fire({
      icon: 'success',
      title: 'Report downloaded',
      text: 'Report has been downloaded successfully',
      showCloseButton: true
    })
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'An error occurred!',
      text: extractErrorMessage(error, 'Failed to download report'),
      showCloseButton: true
    })
  } finally {
    downloadingReport.value = false
  }
}
watch(currentPage, () => { fetchStaff() })
watch(statusFilter, () => { fetchStaff(true) })
watch(dateFilterValid, () => { fetchStaff(true) })
</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show>.nav-link {
  border-radius: 0;
}

.nav-link.active {
  background-color: rgba(0, 0, 0, 0.05);
}

.days_parent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.view-passenger {
  border: 1px solid;
  padding: 0.25rem 0.25rem;
  border-radius: 1rem;
  cursor: pointer;
  font-weight: 500;
  color: #109352;
  font-size: 0.8rem;
}
</style>
